import React from "react";
import BackgroundImage from "gatsby-background-image";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import config from "../../config.js";
import CaptureForm from "../components/CaptureForm";
import Layout from "../components/Layout";
import Share from "../components/Share";
import PostTile from "../components/Blog/PostTile";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/Seo";
import BookAppointment from "../components/BookAppointment";

const BlogPostTemplate = ({ pageContext }) => (
  <Layout
    title={pageContext.title}
    description={pageContext.excerpt.replace(/(<([^>]+)>)/gi, "")}
    seoComp={
      <SEO
        title={pageContext.title}
        description={pageContext.excerpt.replace(/(<([^>]+)>)/gi, "")}
        image={
          pageContext.featuredImage &&
          pageContext.featuredImage.node.localFile &&
          pageContext.featuredImage.node.localFile.childImageSharp.fluid.src.substr(
            1
          )
        }
      ></SEO>
    }
  >
    <Header />
    <header className={``}>
      <BackgroundImage
        Tag="div"
        style={{
          minHeight: "75vh",

          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
        fluid={
          pageContext.featuredImage &&
          pageContext.featuredImage.node.localFile &&
          pageContext.featuredImage.node.localFile.childImageSharp.fluid
        }
        className={"bgImageFixed bg"}
      >
        <div className="gradient h-100 w-100 pt-5">
          <div
            className={`container-fluid d-md-flex w-100 h-100 align-items-center`}
          >
            <div className="row h-100 w-100">
              <div className="col-md-1 col-sm-hidden text-center"></div>
              <div className="col-sm-12 col-md-10 align-middle justify-content-center align-self-center text-center">
                <Zoom>
                  <h1
                    className="m-3 fg-text-color"
                    dangerouslySetInnerHTML={{ __html: pageContext.title }}
                  ></h1>
                </Zoom>
                <Fade top>
                  <div className="mx-auto mt-2 mb-5 fg-text-color share">
                    <Share
                      socialConfig={{
                        config: {
                          url: `${config.url}/artigos/${pageContext.slug}/` /*${socialConfigss.internallink}*/,
                          title: `${pageContext.title}`,
                        },
                      }}
                    />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </header>
    <Share
      floating={true}
      socialConfig={{
        config: {
          url: `${config.url}/artigos/${pageContext.slug}/` /*${socialConfigss.internallink}*/,
          title: `${pageContext.title}`,
        },
      }}
    />
    <section className="post-content pb-5 pl-5 pr-5 pt-2 bg-fg">
      <div className="container-fluid mt-5 mb-5">
        <div className="row mt-5 mb-5">
          <div className="col-md-1 col-sm-hidden"></div>
          <div className="col-sm-12 col-md-10 d-flex justify-content-center align-items-center">
            <div
              className="text-block"
              dangerouslySetInnerHTML={{ __html: pageContext.content }}
            />
          </div>
          <div className="col-md-1 col-sm-hidden"></div>
        </div>
      </div>
    </section>
    <div className="container-fluid">
      <div className="row p-5 d-flex cards justify-content-center primary-bg">
        <div className="col-md-12  text-center pr-0 col-sm-12 text-block ">
          <h1 className=" ">
            O meu propósito é promover a tua felicidade, o teu equilíbrio, a tua
            saúde, o teu bem-estar e a tua realização pessoal e profissional.
          </h1>
        </div>
      </div>
      <div className="row pb-5 d-flex cards justify-content-center primary-bg">
        <div className="col-md-12  text-center pr-0 col-sm-12 text-block  ">
          <BookAppointment
            classes={{ button: "align-middle" }}
            title={"Marcar Sessão"}
            type={" fgBg"}
          />
        </div>
      </div>
    </div>

    <section className="services-section p-5">
      <div className="servicesContainer container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-sm-12 text-center">
            <h1 className="mb-5 secondary-font primary-text-color ">
              <span className="destak">Outros artigos que irás gostar</span>
            </h1>
          </div>
        </div>

        <Fade cascade distance="15px" left>
          <div className="row mb-3 mt-1 justify-content-start">
            {pageContext.recommended.map(({ node }, idx) => (
              <div className={`col-md-4 col-sm-12 mb-3 serviceCard`}>
                <PostTile {...node} />
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </section>
    <CaptureForm />
    <Footer />
  </Layout>
);
export default BlogPostTemplate;
